import axios from "axios";
import {Loader} from "google-charts"

document.addEventListener("DOMContentLoaded", async function () {
  if (document.getElementById("dashboard")) {

    let rawData = [];

    await axios.get('/api/get/scans', {
      params: {
        limit: 5
      }
    }).then((response) => {
      let scans = response.data;

      rawData = [];

      rawData.push([
        "Date",
        "Failed Databases",
        "Websites Failed",
        "No Index",
        "Websites With Space Issues",
      ]);

      for (let scan of scans ) {
        rawData.push([
          formatDate(scan.createdAt),
          scan.failedDatabases,
          scan.websitesFailed,
          scan.noIndexes,
          scan.websitesWithSpaceIssues
        ]);
      }

    });

    google.charts.load('current', {
      'packages': [
        'corechart'
      ]
    });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      let data = google.visualization.arrayToDataTable(rawData);

      var view = new google.visualization.DataView(data);
      view.setColumns([
        0,
        1,
        {
          calc: "stringify",
          type: "string",
          sourceColumn: 1,
          role: "annotation"
        },
        2,
        {
          calc: "stringify",
          type: "string",
          sourceColumn: 2,
          role: "annotation"
        },
        3,
        {
          calc: "stringify",
          type: "string",
          sourceColumn: 3,
          role: "annotation"
        },
        4,
        {
          calc: "stringify",
          type: "string",
          sourceColumn: 4,
          role: "annotation"
        }
      ])

      let options = {
        title: 'Overzicht',
        height: 400,
        vAxis: {
          title: 'amount',
          minValue: 0,
          ticks: [0,1,2,3,4,5,6,7,8,9,10,15,20]
        },
        theme: 'material'
      }

      let chart = new google.visualization.ColumnChart(document.getElementById("summary-chart"));
      chart.draw(view, options);

    }
  }
});

function formatDate(date) {
  date = new Date(date);

  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDay();

  if (day < 10) {
    day = '0' + day
  }

  if (month < 10) {
    month = '0' + month;
  }

  return day + '-' + month + '-' + year;
}
